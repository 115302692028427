<template>
  <div>
    <form novalidate action="" role="form" id="myForm">

      <section class="kjn-hero mb-4">
        <div class="container">
          <h2>Disabilitas</h2>
          <div class="row mt-4">
            <div class="search-bar big">
              <form class="input-group position-search left-bigger align-items-center">
                <input v-model="searchVal" type="text" :placeholder="`Masukkan ${ getHeaderShow() }`" class="form-control" />
                <div class="btn-wrapper input-group-append input-group-lg">
                  <button @click.prevent="toSearch" class="btn btn-ternary text-white">Cari</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </form>

    <section class="container">
      <div class="mt-3">
        <nav aria-label="breadcrumb" style="--bs-breadcrumb-divider: '>';">
          <ol class="breadcrumb breadcrumb-container">
            <li
              :class="breadcrumbClass(idx)"
              aria-current="page"
              v-for="(step, idx) in steps"
              :key="idx"
            >
              <strong>
                <a @click="breadcrumb(step, idx)" style="cursor: pointer;">{{ step.label }}</a>
              </strong>
            </li>
          </ol>
        </nav>
          Menampilkan <strong>{{ total }} {{ getHeaderShow() }} </strong>
      </div>

      <!-- table -->
      <div class="card shadow-sm border-0 mb-3">
        <div class="card-body" style="overflow: auto">
          <table class="table table-search" style="width:100%">

            <thead>
              <tr>
                <th
                  class="border-bottom-0 fit"
                >
                  <h4 class="fw-semibold">{{ tableHeaderShow[0].label }}</h4>
                </th>
                <th
                  class="border-bottom-0"
                >
                  <h4 class="fw-semibold">{{ tableHeaderShow[1].label }}</h4>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr class="align-items-center tbody-tr"
                v-for="(tData, idx) in data"
                :key="idx"
              >
                <td class="text-center">
                  <h5>{{ tData[tableHeaderShow[0].prop] }}</h5>
                </td>
                <td class="text-left">
                  <a @click="toDataDisabilitas(tData)" :class="getTableDataClass()">
                    <h5 style="cursor: pointer;">{{ tData[tableHeaderShow[1].prop] }}</h5>
                  </a>
                </td>
              </tr>

              <tr v-if="(data.length < 1 || data === '') && !isLoading">
                <td colspan="2" class="text-center">Tidak Ada Data</td>
              </tr>

              <tr v-show="isLoading">
                <td colspan="4">
                  <div class="d-flex justify-content-center" style="min-width: 100%">
                    <div class="spinner-border"></div>
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import api from './api'
import swal from 'sweetalert2'

export default {

  data () {
    return {
      data: [],
      tableHeaderShow: [],
      headerKategori: [
        {
          label: 'Kode Kategori/Lapangan Usaha',
          prop: 'kode'
        },
        {
          label: 'Kategori/Lapangan Usaha',
          prop: 'sector'
        },
      ],
      headerSubsector: [
        {
          label: 'Kode Golongan Pokok',
          prop: 'kode'
        },
        {
          label: 'Golongan Pokok',
          prop: 'subsector'
        }
      ],
      headerJabatan: [
        {
          label: 'Kode Jabatan',
          prop: 'kode_jabatan'
        },
        {
          label: 'Nama Jabatan',
          prop: 'nama_jabatan'
        }
      ],
      steps: [
        {
          label: 'Disabilitas',
          content: {
            name: 'disabilitas',
            val: 'disabilitas'
          }
        },
      ],
      nextPageAddress: null,
      currPage: 0,
      lastPage: 1,
      isLoading: false,
      sectorId: null,
      subSectorId: null,
      fromBreadcrump: false,
      limit: 25,
      total: 0,
      searchVal: null,
      isSearch: false
    }
  },
  beforeMount () {
    this.checkParams()
    window.addEventListener('scroll', this.handleScrollKjn)
    // console.log('add event listener')
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScrollKjn);
    // console.log('remove event listener')
  },
  watch: {
    $route (to, from){
      // if (this.fromBreadcrump) {
      //   location.reload()
      // }
      this.checkParams()
    }
  },
  methods: {
    toSearch () {
      const { sector, subSector } = this.$route.query
      if (sector || subSector) {
        console.log('searchVal', this.searchVal)
        this.$router.push(`/home/disabilitas?sector=${sector}${subSector ? '&subSector=' + subSector : "" }&keyword=${this.searchVal}`)
      } else {
        this.$router.push(`/home/quick-search?keyword=${this.searchVal ? this.searchVal : ''}`)
      }
    },
    async checkParams () {
      const { sector, subSector, keyword } = this.$route.query
      this.data = []
      this.nextPageAddress = ''
      this.currPage = 0
      let newStep = [
        {
          label: 'Disabilitas',
          content: {
            name: 'disabilitas',
            val: 'disabilitas'
          }
        }
      ]
      
      if (sector && subSector) {
        this.tableHeaderShow = JSON.parse(JSON.stringify(this.headerJabatan))
        this.sectorId = sector
        this.subSectorId = subSector
        // let data = []
        const data = await this.getJabatanDataDisabilitas(subSector)
        // if (keyword) {
        //   data = await this.searchSubsectorDisabilitas()
        // } else {
        //   data = await this.getJabatanDataDisabilitas(subSector)
        // } 
        // console.log(data[0])
        if (!keyword && data.length > 0) {
          newStep.push({
            label: `${data[0]?.sector_kode ? data[0].sector_kode + ' ' : ''}${data[0].sector}`,
            content: { name: 'sector', val: sector }
          })
          newStep.push({
            label: `${data[0]?.subsector_kode ? data[0].subsector_kode + ' ' : ''}${data[0].subsector}`,
            content: { name: 'subSector', val: subSector }
          })
          this.steps = JSON.parse(JSON.stringify(newStep))
        }
      } else if (sector) {
        this.sectorId = sector
        this.tableHeaderShow = JSON.parse(JSON.stringify(this.headerSubsector))
        const data = await this.getGolonganDataDisabilitas(sector)
        if (keyword && data.length > 0) {
          this.tableHeaderShow = JSON.parse(JSON.stringify(this.headerJabatan))
        }
        if (!keyword && data.length > 0) {
          newStep.push({
            label: `${data[0].sector_kode ? data[0].sector_kode + ' ' : ''}${data[0].sector}`,
            content: { name: 'sector', val: sector }
          })
          this.steps = JSON.parse(JSON.stringify(newStep))
        }
      } else {
        this.tableHeaderShow = JSON.parse(JSON.stringify(this.headerKategori))
        this.getDisabilitasData()
        this.steps = JSON.parse(JSON.stringify(newStep))
      }
    },
    toDisabilitas () {
      if (this.$route.path !== '/home/disabilitas') this.$router.push('/home/disabilitas')
    },
    breadcrumb (step) {
      this.fromBreadcrump = true
      const { keyword, sector, subSector } = this.$route.query
      const lastStep = this.steps[this.steps.length - 1]
      const content = step.content
      this.searchVal = null
      if (content.name == 'sector' && lastStep.name !== 'sector') {
        this.$router.push(`/home/disabilitas?sector=${content.val}`)
      } else if (content.name == 'subSector') {
        if (keyword) {
          this.$router.push(`/home/disabilitas?sector=${sector}&subSector=${subSector}`)
        }
        // this.$router.push(`/home/disabilitas`)
      } else if (content.name == 'disabilitas' && lastStep.name !== 'disabilitas') {
        this.$router.push(`/home/disabilitas`)
      }
    },
    async getDisabilitasData () {
      if (this.currPage == this.lastPage || this.isLoading) return ''
      let notFirstFetch = true
      this.isLoading = true
      const param = { limit: this.limit }
      let res = {}
      try {
        if (this.nextPageAddress !== '') {
          res = await api.getDisabilitasAxios(param, this.nextPageAddress)
        } else {
          res = await api.getDisabilitasAxios(param)
          notFirstFetch = false
          this.currPage = 0
        }

        this.lastPage = res.data.last_page
        const data = res.data.data
        this.total = res.data.total
        this.nextPageAddress = res.data.next_page_url
        if (notFirstFetch) {
          this.data = [ ...JSON.parse(JSON.stringify(this.data)), ...JSON.parse(JSON.stringify(data)) ]
        } else {
          this.data = JSON.parse(JSON.stringify(data))
        }
        this.isLoading = false
      } catch(err) {
        console.log(err)
        this.isLoading = false
        swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error when fetching data'
        })
      }
    },
    async getGolonganDataDisabilitas (s_id) {
      if (this.currPage == this.lastPage || this.isLoading) return ''
      let notFirstFetch = true
      this.isLoading = true
      const { keyword } = this.$route.query
      const param = { limit: this.limit, s_id }
      let res = {}
      try {
        if (keyword) {
          res = await api.getDisabilitasSearchBySector(keyword, param, this.nextPageAddress)
        } else {
          res = await api.getGolonganDisabilitas(param, this.nextPageAddress)
        }
        console.log('res golongan/sector', res)
        if (res.data === '' || res.status === 204) {
          this.data = []
          return []
        } else {
          this.lastPage = res.data.last_page

          const data = res.data.data
          this.total = res.data.total
          this.nextPageAddress = res.data.next_page_url
          if (notFirstFetch) {
            this.data = [ ...JSON.parse(JSON.stringify(this.data)), ...JSON.parse(JSON.stringify(data)) ]
          } else {
            this.data = JSON.parse(JSON.stringify(data))
          }
          return data
        }
      } catch (err) {
        console.log(err)
        swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error when fetching data'
        })
      } finally {
        this.isLoading = false
      }
    },
    async getJabatanDataDisabilitas (ss_id) {
      if (this.currPage == this.lastPage || this.isLoading) return ''
      let notFirstFetch = true
      this.isLoading = true
      const { keyword } = this.$route.query
      const param = { limit: this.limit, s_id: this.sectorId, ss_id }
      let res = {}

      console.log('getJabatanDataDisabilitas', keyword)
      try {
        if (keyword) {
          res = await api.getDisabilitasSearchBySubsector(keyword, param, this.nextPageAddress)
        } else {
          res = await api.getJabatanDisabilitas(param, this.nextPageAddress)
        }
        console.log('res Jabatan/Subsector', res)
        if (res.data === '' || res.status === 204) {
          this.data = []
          return []
        } else {
          this.lastPage = res.data.last_page

          const data = res.data.data
          this.total = res.data.total
          this.nextPageAddress = res.data.next_page_url
          if (notFirstFetch) {
            this.data = [ ...JSON.parse(JSON.stringify(this.data)), ...JSON.parse(JSON.stringify(data)) ]
          } else {
            this.data = JSON.parse(JSON.stringify(data))
          }
          return data
        }
      } catch (err) {
        console.log(err)
        swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error when fetching data'
        })
      } finally {
        this.isLoading = false
      }
    },
    async toDataDisabilitas (data) {
      if (this.tableHeaderShow[1].prop === 'nama_jabatan') {
        window.open(`/home/disabilitas/detail/${data.id}`)
        return ''
      }

      this.data = []
      this.nextPageAddress = null
      this.currPage = 0
      if (this.tableHeaderShow[1].prop === 'sector') {
        this.sectorId = data.id
        this.$router.push(`/home/disabilitas?sector=${data.id}`)
      } else if (this.tableHeaderShow[1].prop === 'subsector') {
        const { sector } = this.$route.query
        this.subSectorId = data.id
        this.$router.push(`/home/disabilitas?sector=${sector}&subSector=${data.id}`)
      } else {
        return ''
      }
    },
    async searchSubsectorDisabilitas () {
      if (this.currPage == this.lastPage || this.isLoading) return 'test'
      let notFirstFetch = true
      this.isLoading = true
      const { sector, subSector, keyword } = this.$route.query
      const param = { limit: this.limit, s_id: sector, ss_id: subSector }
      let res = {}
      try {
        if (this.nextPageAddress !== '') {
          res = await api.getDisabilitasSearchBySubsector(keyword, param, this.nextPageAddress)
        } else {
          res = await api.getDisabilitasSearchBySubsector(keyword, param)
          notFirstFetch = false
          this.currPage = 0
        }

        this.lastPage = res.data.last_page
        console.l0g('data search subSector')
        const data = res.data.data
        this.total = res.data.total
        this.nextPageAddress = res.data.next_page_url
        if (notFirstFetch) {
          this.data = [ ...JSON.parse(JSON.stringify(this.data)), ...JSON.parse(JSON.stringify(data)) ]
        } else {
          this.data = JSON.parse(JSON.stringify(data))
        }
        this.isLoading = false
        return data
      } catch(err) {
        console.log(err)
        this.isLoading = false
        swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error when fetching data'
        })
      }
    },
    breadcrumbClass (idx) {
      let breadClass = 'custom-bread-color breadcrumb-item'
      if (idx == (this.steps.length - 1)) {
        breadClass += ' active'
      } else {
        breadClass += ' text-lighter-custom'
      }
      return breadClass
    },
    getTableDataClass () {
      if (this.tableHeaderShow[1].prop === 'nama_jabatan') {
        return 'text-ternary text-decoration-none fw-medium'
      } else {
        return 'text-primary text-decoration-none fw-medium'
      }
    },
    async handleScrollDisabilitas () {
 
      if (this.isLoading || this.currPage == this.lastPage) return ''

      // let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
      const deviation = 5
      let scrollHeight = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight

      // console.log(`((${scrollHeight - deviation}) <= ${document.documentElement.offsetHeight}) && (${document.documentElement.offsetHeight} <= (${scrollHeight + deviation}))`, ((scrollHeight - deviation) <= document.documentElement.offsetHeight) && (document.documentElement.offsetHeight <= (scrollHeight + deviation)))

      let bottomOfWindow = ((scrollHeight - deviation) <= document.documentElement.offsetHeight) && (document.documentElement.offsetHeight <= (scrollHeight + deviation))
      
      if (bottomOfWindow && this.nextPageAddress !== '') {
        const { sector, subSector, keyword } = this.$route.query
        this.currPage = this.currPage + 1
        if (sector && subSector && keyword) {
          await this.searchSubsectorDisabilitas()
        } else if (this.sectorId && this.subSectorId) {
          await this.getJabatanDataDisabilitas(this.subSectorId)
        } else if (this.sectorId) {
          await this.getGolonganDataDisabilitas(this.sectorId)
        } else {
          await this.getDisabilitasData()
        }
      }
    },
    getHeaderShow () {
      if (this.tableHeaderShow !== null && this.tableHeaderShow !== undefined || this.tableHeaderShow.length > 0) {
        if (this.tableHeaderShow[1].label) {
          return `${this.tableHeaderShow[1].label}`
        } else {
          return 'Jabatan'
        }
      }
    }
  }
}
</script>

<style>
  @import url('../../assets/styles/disabilitas.css');

  .breadcrumb {
    background-color: #e9ecef;
    border-radius: .25rem;
  }

  .custom-bread-color a {
    color: #15406a !important;
  }
</style>